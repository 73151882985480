import { Fragment, useEffect, useState, Component } from "react";
import { useOutletContext } from "react-router-dom";
import { Form } from "react-bootstrap";
import { getFormattedTime, rawFetch } from "../util";
import BlogForm from "../components/base/forms/BlogForms";
import "../components/blog/Blog.css";

export default function Blog() {
  const [user, setUser] = useOutletContext();
  useEffect(() => {
    document.getElementById("title").innerText = "SpEaGs' Blog";
  }, []);
  return <BlogPage user={user} setUser={setUser} />;
}

class BlogPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      channelList: false,
      showNewBlogForm: false,
    };

    this.setSelected = this.setSelected.bind(this);
    this.addEntry = this.addEntry.bind(this);
  }

  componentDidMount() {
    if (!this.state.channelList) {
      rawFetch("bloglist", {
        headers: { "Access-Control-Allow-Origin": "*" },
        method: "GET",
      }).then((list) => {
        return this.setState({ channelList: list });
      });
    }
  }

  setSelected(id) {
    this.setState({ selected: id });
  }

  addEntry(entry) {
    const { channelList } = this.state;
    const index = channelList.findIndex((b) => b._id === entry.channel);
    channelList[index].entries.push(entry);
    this.setState({ channelList: channelList });
  }

  render() {
    const { selected, channelList, showNewBlogForm } = this.state;
    let shownBlog;
    if (!!selected) {
      shownBlog = channelList.find((ch) => ch._id === selected);
    }
    return (
      <div className="blogContainer">
        <div className="blogNavigatorContainer">
          {!!channelList ? (
            <BlogNavigator
              selected={selected}
              setSelected={this.setSelected}
              channelList={channelList}
            />
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
        <div className="blogItemContainer">
          {!selected && (
            <Fragment>
              {!!channelList && channelList.length > 0 ? (
                <BlogNavigator
                  setSelected={this.setSelected}
                  selected={selected}
                  channelList={channelList}
                  large={true}
                />
              ) : (
                <h3>There are no blog topics yet.</h3>
              )}
              {this.props.user.owner && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.setState({ showNewBlogForm: true });
                  }}
                >
                  New Blog
                </button>
              )}
              {showNewBlogForm && (
                <BlogForm.Channel
                  user={this.props.user}
                  closeEdit={(blog, user) => {
                    let channelListOut = channelList;
                    const index = channelListOut.findIndex(
                      (b) => b._id === blog._id
                    );
                    channelListOut[index] = blog;
                    this.setState({
                      selected: blog._id,
                      channelList: channelListOut,
                      showNewBlogForm: false,
                    });
                    this.props.setUser(user);
                  }}
                />
              )}
            </Fragment>
          )}
          {!!selected && (
            <BlogChannel
              user={this.props.user}
              setUser={this.props.setUser}
              addEntry={this.addEntry}
              channel={shownBlog}
            />
          )}
        </div>
      </div>
    );
  }
}

function BlogNavigator(props) {
  const { setSelected, selected, channelList, large } = props;
  if (!channelList || channelList.length === 0)
    return (
      <div className="blogNavigator">
        <h3>empty.</h3>
      </div>
    );
  const blogListJSX = channelList.map((c) => {
    return (
      <li
        className={`blogChannel${selected === c._id ? " selected" : ""}${
          !!large ? " bcl" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (selected !== c._id) setSelected(c._id);
        }}
      >
        <h4 className="blogChannelTitle">{c.title}</h4>
        {large && <h5 className="blogChannelDesc">{c.description}</h5>}
      </li>
    );
  });
  return (
    <div className="blogNavigator">
      <h3>Blog Topics:</h3>
      <ul className="blogListContainer">{blogListJSX}</ul>
    </div>
  );
}

function BlogChannel(props) {
  const [showNewEntryForm, setShowNewEntryForm] = useState(false);
  return (
    <Fragment>
      <h2>{props.channel.title}</h2>
      <h3>{props.channel.description}</h3>
      {!!props.channel.entries &&
        props.channel.entries.length > 0 &&
        props.channel.entries.map((e) => {
          return <BlogChannelEntry entry={e} />;
        })}
      {showNewEntryForm && (
        <BlogForm.Entry
          user={props.user}
          closeEdit={(entry, user) => {
            props.addEntry(entry);
            props.setUser(user);
          }}
        />
      )}
      {!showNewEntryForm && props.user.owner && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowNewEntryForm(true);
          }}
        >
          New Entry
        </button>
      )}
    </Fragment>
  );
}

function BlogChannelEntry(props) {
  const [editing, setEditing] = useState(false);
  const [entry, setEntry] = useState(props.entry);
  const className = `blogEntry_${entry.type}`;
  const timeStamp = (
    <div className="blogEntryTimestamp">{`---- ${entry.timeStamp} ----`}</div>
  );

  switch (entry.type) {
    case "img": {
      if (!editing)
        return (
          <li className={className}>
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEditing(true);
              }}
            >
              Edit
            </button>
            {timeStamp}
            <img src={entry.content.src} alt="oops" />
            <div>{entry.content.description}</div>
          </li>
        );
      else
        return (
          <li className={className}>
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEditing(false);
              }}
            >
              Save
            </button>
            {timeStamp}
            <Form onSubmit={() => {}}>
              <Form.Group size="sm" controlId={entry.type}>
                <Form.Label>Caption</Form.Label>
                <Form.Control
                  type="text"
                  value={entry.content.description}
                  onChange={(e) => {
                    entry.content.description = e.target.value;
                    setEntry(entry);
                  }}
                />
              </Form.Group>
            </Form>
          </li>
        );
    }
    case "img_multi": {
      return (
        <li className={className}>
          {timeStamp}
          <div className="img_multiContainer">
            {entry.content.map((i) => {
              return <img src={i} alt="oops" />;
            })}
          </div>
          <div>{entry.content.description}</div>
        </li>
      );
    }
    case "video": {
      return (
        <li className={className}>
          {timeStamp}Videos not supported currently.
        </li>
      );
    }
    case "link": {
      return (
        <li className={className}>
          {timeStamp}
          <a href={entry.content.href}>{entry.content.name}</a>
          {!!entry.content.description && (
            <div>{entry.content.description}</div>
          )}
        </li>
      );
    }
    default: {
      return (
        <li className={className}>
          {timeStamp}
          {!!entry.header && <h4>{entry.header}</h4>}
          <div>{entry.content.text}</div>
        </li>
      );
    }
  }
}
