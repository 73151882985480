import { Component, Fragment } from "react";
import Btn from "../base/Btn";

const DiscordAuthURL =
  "https://discord.com/api/oauth2/authorize?client_id=1146130754909241439&redirect_uri=https%3A%2F%2Fspeags.com%2F&response_type=code&scope=identify%20guilds";

class AuthPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      showLogin: false,
      showRegister: false,
    };
  }

  render() {
    const { user } = this.state;

    return (
      <Fragment>
        {!user && (
          <Fragment>
            <h2>
              Please log in or register with the buttons at the top right of the
              page!
            </h2>
            <h3>
              You'll probably want to use the Discord option if you're
              registering since you need to have a Discord account linked to use
              this app.
            </h3>
          </Fragment>
        )}
        {!!user && !user.dToken && (
          <Fragment>
            <h2>
              You need to link your discord account to use this app. Here's a
              link to do so:
            </h2>
            <Btn
              key={"discord"}
              title={"Link Discord"}
              href={
                DiscordAuthURL +
                `&state=${window.location.pathname.replace("/", "%2F")}`
              }
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default AuthPage;
