import { Component, useState, Fragment } from "react";
import Upload from "../base/forms/Upload";
import { download } from "../../util";
const { UploadForm, AddDirForm, DelConfirm, ShareMenu } = Upload;

class Dir extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: props.file,
      showUpload: false,
      showDirForm: false,
      showDelConfirm: false,
    };
    this.closeUpload = this.closeUpload.bind(this);
    this.closeAddDir = this.closeAddDir.bind(this);
    this.closeDelConfirm = this.closeDelConfirm.bind(this);
  }

  closeUpload() {
    this.setState({ showUpload: false });
  }

  closeAddDir() {
    this.setState({ showDirForm: false });
  }

  closeDelConfirm() {
    this.setState({ showDelConfirm: false });
  }

  render() {
    const { file, showUpload, showDirForm, showDelConfirm } = this.state;
    const { overSize } = this.props;
    return (
      <div
        className="treeDirTitle"
        onClick={() => {
          this.props.toggleDir(file._id);
        }}
      >
        <h3>{file.name}</h3>
        {!this.props.share && (
          <Fragment>
            <button
              disabled={overSize}
              className="dirUpBtn"
              onClick={(e) => {
                e.stopPropagation();
                this.setState({ showUpload: true });
              }}
            >
              Upload Here
            </button>
            <button
              className="dirAddChildBtn"
              onClick={(e) => {
                e.stopPropagation();
                this.setState({ showDirForm: true });
              }}
            >
              Add New Folder
            </button>
          </Fragment>
        )}
        {file._id !== "root" && !this.props.share && (
          <button
            className="dirDelete"
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ showDelConfirm: true });
            }}
          >
            Delete
          </button>
        )}
        {showUpload && !this.props.share && (
          <UploadForm
            jwt={this.props.user.jwt}
            parent={file._id}
            closeUpload={this.closeUpload}
            setUser={(user) => {
              this.closeUpload();
              this.props.setUser(user);
            }}
          />
        )}
        {showDirForm && !this.props.share && (
          <AddDirForm
            jwt={this.props.jwt}
            parent={file._id}
            closeAddDir={this.closeAddDir}
            setUser={(user) => {
              this.closeAddDir();
              this.props.setUser(user);
            }}
          />
        )}
        {showDelConfirm && !this.props.share && (
          <DelConfirm
            jwt={this.props.jwt}
            file={file}
            closeDelConfirm={this.closeDelConfirm}
            setUser={(user) => {
              this.closeDelConfirm();
              this.props.setUser(user);
            }}
          />
        )}
      </div>
    );
  }
}

const File = (props) => {
  const [showDelConfirm, setShowDelConfirm] = useState();
  const [showShareMenu, setShowShareMenu] = useState();
  return (
    <div className="treeItemFile">
      <div className="treeItemFileName">{props.file.name}</div>
      <button
        className="fileDLBtn"
        onClick={() => {
          download(props.file._id, props.jwt).then((jwt) => {
            props.setJWT(jwt);
          });
        }}
      >
        Download
      </button>
      {!props.share && (
        <button
          className="fileShareBtn"
          onClick={(e) => {
            e.stopPropagation();
            setShowShareMenu(true);
          }}
        >
          Share
        </button>
      )}
      {!props.share && (
        <button
          className="dirDelete"
          onClick={(e) => {
            e.stopPropagation();
            setShowDelConfirm(true);
          }}
        >
          Delete
        </button>
      )}
      {!props.share && <div className="treeItemSize">{props.size}</div>}
      {showShareMenu && !props.share && (
        <ShareMenu
          user={props.user}
          jwt={props.jwt}
          setUser={(user) => {
            setShowShareMenu(false);
            props.setUser(user);
          }}
          file={props.file}
          cloudUsers={props.user.cloudUsers}
          closeShareMenu={() => {
            setShowShareMenu(false);
          }}
        />
      )}
      {showDelConfirm && !props.share && (
        <DelConfirm
          jwt={props.user.jwt}
          file={props.file}
          closeDelConfirm={() => {
            setShowDelConfirm(false);
          }}
          setUser={(user) => {
            setShowDelConfirm(false);
            props.setUser(user);
          }}
        />
      )}
    </div>
  );
};

const DirTree = { Dir: Dir, File: File };
export default DirTree;
