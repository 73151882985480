import { Component, Fragment, createRef } from "react";
import Btn from "./Btn.jsx";
import Button from "react-bootstrap/Button";
import Forms from "./forms/LoginSignup.jsx";

export default class TitleBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: props.mobile,
      title: props.title,
      user: props.user,
      setUser: props.setUser,
      showLogin: false,
      showRegister: false,
      showAuth: false,
      showHamburger: false,
    };
    this.listener = this.listener.bind(this);
    this.ref = createRef();
    this.ref2 = createRef();
    this.ref3 = createRef();

    this.hamburgerItems = require("../../routes/links.json").map((l) => {
      return (
        <Btn
          key={l.title}
          title={l.title}
          href={l.href}
          style={l.style}
          color={l.color}
          onClick={() => {
            this.setState({ showHamburger: false });
          }}
        />
      );
    });
  }

  listener(e) {
    if (
      !this.ref.current ||
      this.ref.current.contains(e.target) ||
      !this.ref2.current ||
      this.ref2.current.contains(e.target) ||
      !this.ref3.current ||
      this.ref3.current.contains(e.target)
    )
      return;
    this.setState({
      showAuth: false,
      showLogin: false,
      showRegister: false,
      showHamburger: false,
    });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.listener);
    document.addEventListener("touchstart", this.listener);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.listener);
    document.removeEventListener("touchstart", this.listener);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.user !== this.props.user)
      this.setState({ user: this.props.user });
  }

  render() {
    const {
      title,
      user,
      showRegister,
      showLogin,
      mobile,
      showAuth,
      showHamburger,
    } = this.state;
    const { style, doHandleAuth } = this.props;
    return (
      <Fragment>
        <div className="titleBar" style={style}>
          <div ref={this.ref3} className="hamburgerContainer">
            <button
              className="hamburgerBtn"
              onClick={() => {
                this.setState({ showHamburger: !showHamburger });
              }}
            >
              =
            </button>
            <div
              className={`hamburgerContents ${showHamburger ? "active" : ""}`}
            >
              {this.hamburgerItems}
            </div>
          </div>
          <div>{title}</div>
          <div
            ref={this.ref}
            className={
              mobile
                ? `authContainerM ${showAuth ? "active" : ""}`
                : "authContainer"
            }
          >
            {mobile && (
              <div
                className="authContainerMToggle"
                onClick={() => {
                  this.setState({ showAuth: !showAuth });
                }}
              >
                =
              </div>
            )}
            {!user && (
              <Fragment>
                <Button
                  size="sm"
                  onClick={() => {
                    this.setState({
                      showLogin: !showLogin,
                      showAuth: false,
                      showRegister: false,
                    });
                  }}
                >
                  Log In
                </Button>
                <Button
                  size="sm"
                  onClick={() => {
                    this.setState({
                      showRegister: !showRegister,
                      showAuth: false,
                      showLogin: false,
                    });
                  }}
                >
                  Sign Up
                </Button>
              </Fragment>
            )}
            {user && (
              <Fragment>
                <div>{user.username}</div>
                <Button
                  size="sm"
                  onClick={() => {
                    doHandleAuth("logout");
                  }}
                >
                  Log Out
                </Button>
              </Fragment>
            )}
          </div>
        </div>
        {!user && (
          <div ref={this.ref2} className={`authFormContainer`}>
            {
              <Forms.SignupForm
                style={showRegister ? "active" : ""}
                onAuth={doHandleAuth}
              />
            }
            {
              <Forms.LoginForm
                style={showLogin ? "active" : ""}
                onAuth={doHandleAuth}
              />
            }
          </div>
        )}
      </Fragment>
    );
  }
}
