import { useState } from "react";
import { Link } from "react-router-dom";

export default function Btn(props) {
  const [isHover, setIsHover] = useState(false);
  const { title, href, onClick } = props;
  let { color } = props;
  if (!color) color = "Blue";
  const colorStyle = {
    backgroundColor: `var(--${color}${isHover ? "2" : "1"})`,
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const LJSX = href.includes("https") ? (
    <a
      style={colorStyle}
      href={href}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      {title}
    </a>
  ) : (
    <Link
      to={href}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      {title}
    </Link>
  );

  return LJSX;
}
