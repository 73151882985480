import { Component } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Btn from "../Btn";
import { apiFetch } from "../../../util";

const DiscordAuthURL =
  "https://discord.com/api/oauth2/authorize?client_id=1146130754909241439&redirect_uri=https%3A%2F%2Fspeags.com%2F&response_type=code&scope=identify%20guilds%20email";

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      username: "",
      email: "",
      password: "",
      passwordConfirm: "",
      keep: false,
      style: props.style,
      UEE: false,
    };
  }

  validateEmail(email) {
    if (email.length > 0) {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
    } else return true;
  }

  validateForm() {
    return (
      this.state.username.length > 0 &&
      this.validateEmail(this.state.email) &&
      this.state.password.length > 0 &&
      this.state.password === this.state.passwordConfirm
    );
  }

  handleSubmit(e, fData) {
    e.preventDefault();
    const { username, email, password, keep } = fData;
    const params = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "POST",
      body: JSON.stringify({
        username: username,
        email: email,
        password: password,
        keep: keep,
      }),
    };
    apiFetch("register", params).then((result) => {
      if (result.err) {
        setTimeout(() => {
          this.setState({ UEE: false });
        }, 10 * 1000);
        return this.setState({ UEE: true });
      }
      const user = result.data;
      user.jwt = result.jwt;
      this.props.onAuth("login", user);
    });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.style !== this.props.style) {
      this.setState({ style: this.props.style });
    }
  }

  render() {
    const { username, email, password, passwordConfirm, keep, style, UEE } =
      this.state;
    return (
      <div className={`registerForm ${style}`}>
        <Form
          onSubmit={(e) => {
            this.handleSubmit(e, {
              username: username,
              email: !!email ? email : "",
              password: password,
              keep: keep,
            });
          }}
        >
          <h2>Sign up</h2>
          {UEE && <h4 className="icli">Username is taken!</h4>}
          <Form.Group size="sm" controlId="reg-username">
            <Form.Label>Username*</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              value={username}
              onChange={(e) => this.setState({ username: e.target.value })}
            />
          </Form.Group>
          <Form.Group size="sm" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              value={email}
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
            />
          </Form.Group>
          <Form.Group size="sm" controlId="reg-password">
            <Form.Label>Password*</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
            />
          </Form.Group>
          <Form.Group size="sm" controlId="passwordConfirm">
            <Form.Label>Confirm Password*</Form.Label>
            <Form.Control
              type="password"
              value={passwordConfirm}
              onChange={(e) => {
                this.setState({ passwordConfirm: e.target.value });
              }}
            />
          </Form.Group>
          <h6>* = required</h6>
          <h6>**must have a verified email for some services**</h6>
          <Button size="sm" type="submit" disabled={!this.validateForm()}>
            Submit
          </Button>
          <h2>Or register with:</h2>
          <Btn
            key={"discord"}
            title={"Discord"}
            href={
              DiscordAuthURL +
              `&state=${encodeURIComponent(
                JSON.stringify({ loc: window.location.pathname, keep: keep })
              )}`
            }
          />
          <Form.Group>
            <Form.Check
              size="sm"
              type="checkbox"
              label="Keep me logged in"
              onChange={(e) => {
                this.setState({ keep: e.target.checked });
              }}
            />
          </Form.Group>
        </Form>
      </div>
    );
  }
}

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      username: "",
      password: "",
      keep: false,
      style: props.style,
      ICLI: false,
    };
  }
  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleSubmit(e, fData) {
    e.preventDefault();
    const { username, password, keep } = fData;
    const params = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "POST",
      body: JSON.stringify({
        authType: "pass",
        username: username,
        password: password,
        keep: keep,
      }),
    };
    apiFetch("auth", params).then((result) => {
      if (!!result.err) {
        setTimeout(() => {
          this.setState({ ICLI: false });
        }, 10 * 1000);
        return this.setState({ ICLI: true });
      }
      const user = result.data;
      user.jwt = result.jwt;
      const doAuth = () => {
        this.props.onAuth("login", user);
      };
      if (!user.flags.cloud) {
        const fileParams = {
          headers: { "Access-Control-Allow-Origin": "*", jwt: user.jwt },
          method: "GET",
        };
        apiFetch(`files`, fileParams).then((res) => {
          user.files = res.data;
          user.jwt = res.jwt;
          doAuth();
        });
      } else doAuth();
    });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.style !== this.props.style) {
      this.setState({ style: this.props.style });
    }
  }

  render() {
    const { username, password, keep, style, ICLI } = this.state;
    return (
      <div className={`registerForm ${style}`}>
        <Form
          onSubmit={(e) => {
            this.handleSubmit(e, {
              username: username,
              password: password,
              keep: keep,
            });
          }}
        >
          <h2>Login</h2>
          {ICLI && <h4 className="icli">Username or Password incorrect!</h4>}
          <Form.Group size="sm" controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              autoFocus
              type="text"
              value={username}
              onChange={(e) => this.setState({ username: e.target.value })}
            />
          </Form.Group>
          <Form.Group size="sm" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
            />
          </Form.Group>
          <Button size="sm" type="submit" disabled={!this.validateForm()}>
            Submit
          </Button>
          <h2>Or login with:</h2>
          <Btn
            key={"discord"}
            title={"Discord"}
            href={
              DiscordAuthURL +
              `&state=${encodeURIComponent(
                JSON.stringify({ loc: window.location.pathname, keep: keep })
              )}`
            }
          />
          <Form.Group>
            <Form.Check
              size="sm"
              type="checkbox"
              label="Keep me logged in"
              onChange={(e) => {
                this.setState({ keep: e.target.checked });
              }}
            />
          </Form.Group>
        </Form>
      </div>
    );
  }
}
const Forms = { SignupForm: SignupForm, LoginForm: LoginForm };
export default Forms;
