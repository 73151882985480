import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function AppTile(props) {
  const { link, imgSrc, title, description } = props;
  const lContents = (
    <Fragment>
      <h3>{title}</h3>
      <h4>{description}</h4>
      <img src={imgSrc} alt="Uhh... oops? This couldn't be loaded!" />
    </Fragment>
  );
  const LJSX = link.includes("https") ? (
    <a href={link} className="App-tile">
      {lContents}
    </a>
  ) : (
    <Link to={link} className="App-tile">
      {lContents}
    </Link>
  );
  return LJSX;
}
