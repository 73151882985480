import { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import Btn from "../Btn";
import Button from "react-bootstrap/Button";
import { apiFetch } from "../../../util";

const DiscordAuthURL =
  "https://discord.com/api/oauth2/authorize?client_id=1146130754909241439&redirect_uri=https%3A%2F%2Fspeags.com%2F&response_type=code&scope=identify%20guilds%20email";

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      enableEmail: this.props.user.flags.email,
      enablePass: this.props.user.flags.pass,
      enableDiscord: this.props.user.flags.discord,
      emailInput: "",
      oldPassInput: "",
      passInput: "",
      confirmPassInput: "",
      showConfirmDel: false,
      showFinalConfirmDel: false,
      confirmDelUname: "",
    };
    this.validateForm = this.validateForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.user !== this.props.user && !!this.props.user)
      return this.setState({ user: this.props.user });
  }

  validateForm() {
    const { emailInput, oldPassInput, passInput, confirmPassInput } =
      this.state;
    return (
      (emailInput.length > 0
        ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailInput)
        : true) &&
      (passInput.length > 0 ? passInput === confirmPassInput : true) &&
      !(emailInput.length === 0 && passInput.length === 0) &&
      (oldPassInput.length > 0
        ? passInput.length > 0 && passInput === confirmPassInput
        : true)
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    const { emailInput, passInput, oldPassInput, user, enablePass } =
      this.state;
    const targets = [];
    if (!!emailInput) targets.push("email");
    if (!!passInput) enablePass ? targets.push("pass") : targets.push("chpass");
    const params = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        jwt: user.jwt,
      },
      method: "POST",
      body: JSON.stringify({
        targets: targets,
        payload: {
          email: !!emailInput ? emailInput : false,
          pass: !!passInput ? passInput : false,
          oldPass: !!oldPassInput ? oldPassInput : false,
        },
      }),
    };
    apiFetch("update", params).then((result) => {
      const updatedUser = result.data;
      updatedUser.jwt = result.jwt;
      this.props.setEditing(false);
      this.props.setUser(user);
    });
  }

  render() {
    const {
      user,
      enableEmail,
      enablePass,
      enableDiscord,
      emailInput,
      oldPassInput,
      passInput,
      confirmPassInput,
      showConfirmDel,
      showFinalConfirmDel,
      confirmDelUname,
    } = this.state;
    return (
      <div className={"profileForm"}>
        <Form
          onSubmit={(e) => {
            this.handleSubmit(e);
          }}
        >
          {enableEmail && (
            <Form.Group size="sm" controlId="ch-email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                value={emailInput}
                onChange={(e) => {
                  this.setState({ emailInput: e.target.value });
                }}
              />
            </Form.Group>
          )}
          {enablePass && (
            <Fragment>
              <Form.Group size="sm" controlId="ch-pass">
                <Form.Label>Add Password</Form.Label>
                <Form.Control
                  type="password"
                  value={passInput}
                  onChange={(e) => {
                    this.setState({ passInput: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group size="sm" controlId="conf-ch-pass">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  value={confirmPassInput}
                  onChange={(e) => {
                    this.setState({ confirmPassInput: e.target.value });
                  }}
                />
              </Form.Group>
            </Fragment>
          )}
          {!enablePass && (
            <Fragment>
              <Form.Group size="sm" controlId="ch-old-pass">
                <Form.Label>Current Password</Form.Label>
                <Form.Control
                  type="password"
                  value={oldPassInput}
                  onChange={(e) => {
                    this.setState({ oldPassInput: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group size="sm" controlId="ch-pass">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={passInput}
                  onChange={(e) => {
                    this.setState({ passInput: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group size="sm" controlId="conf-ch-pass">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={confirmPassInput}
                  onChange={(e) => {
                    this.setState({ confirmPassInput: e.target.value });
                  }}
                />
              </Form.Group>
            </Fragment>
          )}
          <button
            className="red"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ showConfirmDel: true });
            }}
          >
            Delete User
          </button>
          {showConfirmDel && (
            <div className="popupFormContainer">
              <div>
                Are you sure? This is irreversible.
                <button
                  className="red"
                  onClick={() => {
                    this.setState({
                      showFinalConfirmDel: true,
                      showConfirmDel: false,
                    });
                  }}
                >
                  I am sure.
                </button>
                <button
                  onClick={() => {
                    this.setState({ showConfirmDel: false });
                  }}
                >
                  Never mind.
                </button>
              </div>
            </div>
          )}
          {showFinalConfirmDel && (
            <div className="popupFormContainer">
              <div>
                WARNING: This is irreversible! -- Type your username to delete
                your account.
                <input
                  type="text"
                  value={confirmDelUname}
                  onChange={(e) => {
                    this.setState({ confirmDelUname: e.target.value });
                  }}
                />
                <button
                  disabled={confirmDelUname !== user.username}
                  className="red"
                  onClick={(e) => {
                    e.preventDefault();
                    const params = {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        jwt: user.jwt,
                      },
                      method: "GET",
                    };
                    apiFetch("delete", params).then((result) => {
                      localStorage.removeItem("usrtkn");
                      sessionStorage.removeItem("usrtkn");
                      this.props.setUser(false);
                    });
                  }}
                >
                  Delete my account.
                </button>
                <button
                  onClick={() => {
                    this.setState({ showFinalConfirmDel: false });
                  }}
                >
                  Never mind.
                </button>
              </div>
            </div>
          )}
          {enableDiscord && (
            <Btn
              key="discord"
              title="Connect to Discord"
              href={
                DiscordAuthURL +
                `&state=${encodeURIComponent(
                  JSON.stringify({
                    loc: window.location.pathname,
                    jwt: user.jwt,
                  })
                )}`
              }
            />
          )}
          <Button size="sm" type="submit" disabled={!this.validateForm()}>
            Submit
          </Button>
          <button
            onClick={(e) => {
              e.preventDefault();
              this.props.setEditing(false);
            }}
          >
            X
          </button>
        </Form>
      </div>
    );
  }
}

export default ProfileForm;
