import { Component, Fragment, useRef, useEffect } from "react";
import { FormControl } from "react-bootstrap";

function ConsoleItem(props) {
  let l = props.l;
  let level = "";
  switch (l.level) {
    case "INFO": {
      level = "consoleINFO";
      break;
    }
    case "WARN": {
      level = "consoleWARN";
      break;
    }
    case "ERROR": {
      level = "consoleERR";
      break;
    }
    default: {
      level = "consoleINFO";
      break;
    }
  }

  const div = useRef(null);
  useEffect(
    () => div.current.scrollIntoView({ behavior: "smooth", block: "end" }),
    []
  );
  return (
    <div className={`consoleItem ${level}`} ref={div}>
      {`${l.timeStamp} ${l.tags.join(" ")}: ${l.msg}`}
    </div>
  );
}

export default class AdminConsole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backlog: props.backlog,
      cmdInput: "",
      cmdList: props.cmdList,
    };
    this.toggleConsole = this.toggleConsole.bind(this);
    this.toggleToggles = this.toggleToggles.bind(this);
    this.doSendCMD = props.doSendCMD;
    this.cmdToggles = props.cmdList.map((c) => {
      return (
        <label key={c.name} className={"dropdown-item-toggle"}>
          <div>{c.name}</div>
          <input
            key={Math.random()}
            type="checkbox"
            defaultChecked={c.state}
            onChange={(e) => {
              let cmdsOut = this.state.cmdList;
              cmdsOut.find((i) => i.name === c.name).state = e.target.checked;
              this.doSendCMD("togglecmd", {
                name: c.name,
                state: e.target.checked,
              });
              this.setState({ cmdList: cmdsOut });
            }}
          />
          <span className="toggleSlider" />
        </label>
      );
    });
  }
  componentDidUpdate(oldProps) {
    if (
      oldProps.backlog[oldProps.backlog.length - 1].timeStamp !==
      this.props.backlog[this.props.backlog.length - 1].timeStamp
    ) {
      this.setState({ backlog: this.props.backlog });
    }
  }
  toggleConsole() {
    this.setState({ showConsole: !this.state.showConsole });
  }
  toggleToggles() {
    this.setState({ showToggles: !this.state.showToggles });
  }
  render() {
    const { backlog } = this.state;
    let logKey = 0;
    let logList = backlog.map((l) => {
      logKey++;
      return <ConsoleItem l={l} key={logKey} />;
    });
    return (
      <Fragment>
        <button onClick={this.toggleConsole} className="consoleToggle">
          Toggle Console
        </button>
        {this.state.showConsole && (
          <div className="adminConsoleContainer">
            <div className="adminConsoleLogs">{logList}</div>
            <FormControl
              type="text"
              placeholder="cmd input or broadcast"
              value={this.state.cmdInput}
              className="adminConsoleInput"
              onKeyPress={(e) => {
                if (e.key === "Enter" && !!this.state.cmdInput) {
                  this.doSendCMD(this.state.cmdInput);
                  this.setState({ cmdInput: "" });
                }
              }}
              onChange={(e) => {
                this.setState({ cmdInput: e.target.value });
              }}
            />
            <div className="cmdToggleExpander">
              <button onClick={this.toggleToggles}>{`${
                this.state.showToggles ? "Hide" : "Show"
              } Command Toggles`}</button>
              {this.state.showToggles && (
                <div className={"cmdTogglesContainer"}>{this.cmdToggles}</div>
              )}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}
