const USER_API = "https://speags.com/apis/user/";

export function rawFetch(endpoint, params) {
  return new Promise((resolve, reject) => {
    fetch(`${USER_API}${endpoint}`, params).then((res) => {
      res.json().then((data) => {
        if (res.status === 200) {
          resolve(data);
        } else
          resolve({
            err: `Error fetching from API: ${data.message}`,
            code: res.status,
          });
      });
    });
  });
}

export function apiFetch(endpoint, params) {
  return new Promise((resolve, reject) => {
    let newJWT = "";
    fetch(`${USER_API}${endpoint}`, params)
      .then((res) => {
        if (res.status === 200) {
          newJWT = res.headers.get("jwt");
          res.json().then((data) => {
            if (!newJWT && !data) resolve(null);
            else
              resolve({
                jwt: newJWT ? newJWT : null,
                data: data ? data : null,
              });
          });
        } else {
          return res.json().then((data) => {
            resolve({
              err: `Error fetching from API: ${data.message}`,
              code: res.status,
            });
          });
        }
      })
      .catch((error) => {
        reject(`Unexpected Error when fetching from API: ${error}`);
      });
  });
}

export function download(fid, jwt) {
  return new Promise(async (resolve, reject) => {
    const DLParams = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "file-id": fid,
        jwt: jwt,
      },
      method: "GET",
    };
    const res = await fetch(`${USER_API}files`, DLParams);
    if ((res.code = 200)) {
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const contentDisposition = res.headers.get("Content-Disposition");
      const fileName = contentDisposition
        .split(";")
        .find((param) => param.trim().startsWith("filename="))
        .split("=")[1];
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      resolve(res.headers.get("jwt"));
    } else reject(new Error(`Response code ${res.code}`));
  });
}
