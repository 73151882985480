import { Component } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { apiFetch } from "../../../util";

class UploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = { files: [], uploadProgress: 0 };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { files } = this.state;
    const { parent, jwt } = this.props;
    const sizeThreshold = 2 * 1024 * 1024; //2MB
    const reqCount = files.length + 1;
    let progress = 0;

    const makeID = (length) => {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    };

    const handleChunkedUpload = (chunkData, jwt, parent) => {
      return new Promise((resolve, reject) => {
        const params = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "parent-id": parent,
          },
          method: "POST",
        };

        const doChunkUpload = (chunk, jwt) => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            params.headers.chunkData = JSON.stringify({
              name: chunkData.fileName,
              tempID: chunkData.tempID,
              part: chunkData.chunks.indexOf(chunk),
              chunkCount: chunkData.chunks.length,
              totalSize: chunkData.totalSize,
            });
            formData.append("file", chunk);
            params.headers.jwt = jwt;
            params.body = formData;
            console.log(params);
            apiFetch("upload", params).then((res) => {
              resolve(res);
            });
          });
        };
        let count = 0;
        let result = { jwt: jwt };
        const iterateChunks = () => {
          doChunkUpload(chunkData.chunks[count], result.jwt).then((res) => {
            count++;
            result = res;
            if (count > chunkData.chunks.length - 1) return resolve(result);
            else iterateChunks();
          });
        };
        iterateChunks();
      });
    };

    const handleMultiUpload = async (arr = files, tkn = jwt) => {
      const f = files.shift();
      const params = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          jwt: tkn,
          "parent-id": parent,
        },
        method: "POST",
        body: new FormData(),
      };
      const afterUpload = (result) => {
        progress++;
        this.setState({ uploadProgress: (progress / reqCount) * 100 }, () => {
          if (files.length > 0) return handleMultiUpload(arr, result.jwt);
          else {
            const user = result.data;
            user.jwt = result.jwt;
            return this.props.setUser(user);
          }
        });
      };
      if (!f) return;
      if (f.size >= sizeThreshold) {
        const chunkSize = sizeThreshold;
        const tempID = makeID(16);
        const fileName = f.name;
        const totalChunks = Math.ceil(f.size / chunkSize);

        const chunkData = {
          fileName: fileName,
          tempID: tempID,
          totalChunks: totalChunks,
          chunks: [],
          totalSize: f.size,
        };
        for (let start = 0; start < f.size; start += chunkSize) {
          const end = Math.min(start + chunkSize, f.size);
          const chunk = f.slice(start, end);
          chunkData.chunks.push(chunk);
        }
        handleChunkedUpload(chunkData, tkn, parent).then(afterUpload);
      } else {
        params.body.append("file", f);
        params.headers.jwt = tkn;
        apiFetch("upload", params).then(afterUpload);
      }
    };
    handleMultiUpload();
  }

  render() {
    const { files, uploadProgress } = this.state;
    const { parent, jwt } = this.props;
    return (
      <div
        className="popupFormContainer"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit(parent, jwt);
          }}
        >
          <Form.Group controlId="uploadFileMulti" className="fileUpload">
            <Form.Label>Upload a file!</Form.Label>
            <Form.Control
              type="file"
              multiple={true}
              onChange={(e) => {
                e.preventDefault();
                let filesOut = Object.values(e.target.files);
                this.setState({ files: filesOut });
              }}
            />
          </Form.Group>
          <Button size="sm" type="submit" disabled={!files.length}>
            Submit
          </Button>
        </Form>
        {uploadProgress > 0 && `Uploading... ${uploadProgress}%`}
        <button onClick={this.props.closeUpload}>X</button>
      </div>
    );
  }
}

class AddDirForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dirName: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(name, parent, jwt) {
    const params = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        jwt: jwt,
      },
      method: "POST",
      body: JSON.stringify({ parent: parent, name: name }),
    };
    apiFetch("dir", params).then((result) => {
      const user = result.data;
      user.jwt = result.jwt;
      return this.props.setUser(user);
    });
  }

  render() {
    const { dirName } = this.state;
    const { jwt, parent, closeAddDir } = this.props;
    return (
      <div
        className="popupFormContainer"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit(dirName, parent, jwt);
          }}
        >
          <Form.Group controlId="dirNameInput" className="dirNameInputField">
            <Form.Label>Create new folder:</Form.Label>
            <Form.Control
              type="text"
              value={dirName}
              autoFocus
              onChange={(e) => {
                this.setState({ dirName: e.target.value });
              }}
            />
            <Button size="sm" type="submit" disabled={dirName.length === 0}>
              Add Folder
            </Button>
          </Form.Group>
        </Form>
        <button onClick={closeAddDir}>X</button>
      </div>
    );
  }
}

class DelConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = { fname: "" };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(file, jwt) {
    switch (file.type) {
      case "d": {
        const params = {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            jwt: jwt,
          },
          method: "POST",
          body: JSON.stringify({ parent: file.parent, rmid: file._id }),
        };
        return apiFetch("dir", params).then((result) => {
          const user = result.data;
          user.jwt = result.jwt;
          this.props.setUser(user);
        });
      }
      case "f": {
        const params = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            jwt: jwt,
            "parent-id": file.parent,
            rm: "true",
            "file-id": file._id,
          },
        };
        return apiFetch("files", params).then((result) => {
          const user = result.data;
          user.jwt = result.jwt;
          this.props.setUser(user);
        });
      }
      default: {
        break;
      }
    }
  }

  render() {
    const { file, jwt, closeDelConfirm } = this.props;
    const { fname } = this.state;
    return (
      <div
        className="popupFormContainer"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit(file, jwt);
          }}
        >
          <Form.Group
            controlId="confirmDeleteInput"
            className="confirmDeleteInputField"
          >
            <Form.Label>{`Type ${file.name} to delete. Warning: deletion is final!`}</Form.Label>
            <Form.Control
              type="text"
              value={fname}
              autoFocus
              onChange={(e) => {
                this.setState({ fname: e.target.value });
              }}
            />
            <Button
              size="sm"
              type="submit"
              disabled={!(fname === file.name)}
              className={"delConfirmSubmit"}
            >
              DELETE
            </Button>
          </Form.Group>
        </Form>
        <button onClick={closeDelConfirm}>X</button>
      </div>
    );
  }
}

class ShareMenu extends Component {
  constructor(props) {
    super(props);
    let data = {};
    props.cloudUsers.forEach((u) => {
      data[u._id] = props.file.shares.includes(u._id);
    });
    this.state = {
      filter: "",
      change: false,
      changedData: data,
    };

    this.buildOptions = this.buildOptions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  buildOptions(shares) {
    const { filter, changedData } = this.state;
    let opts = [];
    this.props.cloudUsers.forEach((u) => {
      if (
        (filter && u.username.includes(filter)) ||
        (u.email && u.email.includes(filter))
      )
        opts.push(
          <Form.Check
            type="checkbox"
            id={`shareCheck_${u._id}`}
            label={`${u.username}${u.email ? ` (${u.email})` : ``}`}
            checked={changedData[u._id]}
            onChange={(e) => {
              changedData[u._id] = e.target.checked;
              this.setState({ changedData: changedData, change: true });
            }}
          />
        );
    });
    return opts;
  }

  handleSubmit() {
    const { changedData } = this.state;
    const { jwt } = this.props;
    const params = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        jwt: jwt,
        fid: this.props.file._id,
      },
      method: "POST",
      body: JSON.stringify(changedData),
    };
    apiFetch("share", params).then((result) => {
      const user = result.data;
      user.jwt = result.jwt;
      this.props.setUser(user);
    });
  }

  render() {
    const { filter, change } = this.state;
    const { closeShareMenu, file } = this.props;
    const opts = this.buildOptions(file.shares);
    return (
      <div
        className="popupFormContainer"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <Form.Group controlId="shareUserFilter">
            <Form.Label>Search users:</Form.Label>
            <input
              type="text"
              value={filter}
              autoFocus
              onChange={(e) => {
                this.setState({ filter: e.target.value });
              }}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                this.setState({ filter: "" });
              }}
            >
              Clear
            </button>
          </Form.Group>
          <Form.Group controlId="shareList" className="shareListInputField">
            {opts}
            <Button size="sm" type="submit" disabled={!change}>
              Save Changes
            </Button>
          </Form.Group>
        </Form>
        <button onClick={closeShareMenu}>X</button>
      </div>
    );
  }
}

const Upload = {
  UploadForm: UploadForm,
  AddDirForm: AddDirForm,
  DelConfirm: DelConfirm,
  ShareMenu: ShareMenu,
};

export default Upload;
