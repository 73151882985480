import { Component, Fragment, useEffect } from "react";
import ProfileForm from "../components/base/forms/Profile";
import AuthPage from "../components/voidbot/AuthPage";
import { useOutletContext } from "react-router-dom";
import "../components/profile/profile.css";
import { apiFetch } from "../util";

export default function Profile() {
  const [user, setUser] = useOutletContext();
  useEffect(() => {
    document.getElementById("title").innerText = `${
      user
        ? `${user.username}${
            user.username.toLowerCase().endsWith("s")
              ? "' Profile"
              : "'s Profile"
          }`
        : "Not logged in."
    }`;
  }, [user]);
  return <UserProfile user={user} setUser={setUser} />;
}

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { user: this.props.user, redirect: false, editing: false };
    this.setEditing = this.setEditing.bind(this);
  }

  setEditing(bool) {
    this.setState({ editing: bool });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.user !== this.props.user)
      return this.setState({ user: this.props.user });
  }

  render() {
    const { user, editing } = this.state;
    return (
      <Fragment>
        {!user && <AuthPage />}
        {!!user && !editing && (
          <div className="profileGridContainer">
            <div className="unameDisplay">{`Username: ${user.username}`}</div>
            <button
              className="editBtn"
              onClick={() => {
                this.setState({ editing: true });
              }}
            >
              Edit
            </button>
            <div className="emailDisplay profileItem">
              {`Email: ${!!user.email ? user.email : "none"}`}
            </div>
            {!!user.email && (
              <div className="verified profileItem">
                {`Email verified: ${
                  !user.flags.verified
                    ? "Yes"
                    : `No${
                        user.flags.verified
                          ? ", Add and verify your email to request access."
                          : ""
                      }`
                }`}
              </div>
            )}
            <div className="cloudAccess profileItem">
              {`Cloud access: ${!user.flags.cloud ? "Yes" : "No"}\n`}
              {user.flags.cloud &&
                user.flags.cloudRequest &&
                !user.flags.verified && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      const params = {
                        headers: {
                          "Access-Control-Allow-Origin": "*",
                          jwt: user.jwt,
                        },
                        method: "GET",
                      };
                      apiFetch("cloudrequest", params).then((result) => {
                        const user = result.data;
                        user.jwt = result.jwt;
                        return this.props.setUser(user);
                      });
                    }}
                  >
                    Request Access
                  </button>
                )}
            </div>
            <div className="discord profileItem">{`Discord connected: ${
              !user.flags.discord ? `Yes: ${user.discordHandle}` : "No"
            }`}</div>
            <div className="passwordLogin profileItem">{`Password login: ${
              !user.flags.pass
                ? "Enabled"
                : "Disabled. Add a password to enable."
            }`}</div>
          </div>
        )}
        {!!user && editing && (
          <ProfileForm
            user={user}
            setUser={this.props.setUser}
            setEditing={this.setEditing}
          />
        )}
      </Fragment>
    );
  }
}
