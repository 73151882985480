import { Component, Fragment, useEffect, useState } from "react";
import { DropdownButton, Dropdown, Button, FormControl } from "react-bootstrap";

function msToTime(ms) {
  let time = {
    secs: Math.floor((ms / 1000) % 60),
    mins: Math.floor((ms / 1000 / 60) % 60),
    hours: Math.floor((ms / 1000 / 60 / 60) % 24),
  };
  let timeOut = [];
  timeOut.unshift(time.secs.toString().padStart(2, "0"));
  timeOut.unshift(
    time.hours > 0
      ? time.mins.toString().padStart(2, "0")
      : time.mins.toString()
  );
  if (time.hours > 0) timeOut.unshift(time.hours);
  return timeOut.join(":");
}

function SongItem(props) {
  let ms = props.vidInfo.duration * 1000;
  let startedAt = !!props.list ? false : props.vidInfo.lastPlayed;
  let dur = msToTime(ms);
  let msLeft = !!props.list ? false : ms - Math.abs(Date.now() - startedAt);
  let [tLeft, setTLeft] = useState(!!props.list ? false : msToTime(msLeft));

  useEffect(() => {
    if (!props.list) {
      let timer = setTimeout(() => {
        setTLeft(msToTime(msLeft));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  function openVidLink() {
    window.open(props.vidInfo.url);
  }

  let trackSource = "";
  switch (props.vidInfo.trackSource) {
    case "SC":
      trackSource = "SoundCloud.";
      break;
    case "YT":
      trackSource = "YouTube.";
      break;
    default:
      break;
  }

  return (
    <Fragment>
      {!props.list && (
        <div className={"vidInfoContainer"}>
          {props.vidInfo.imgURL && (
            <img
              src={props.vidInfo.imgURL}
              alt="new"
              className="gridWMD albumArt"
            />
          )}
          {!props.vidInfo.imgURL && (
            <img src="" className="albumArt" alt="Failed to load thumbnail." />
          )}
          <div className="gridWXL gridHMD songTitle" onClick={openVidLink}>
            {props.vidInfo.title} - [{tLeft}]/[{dur}]
          </div>
          <Button
            className="pauseResumeBtn"
            onClick={() => {
              let action = props.paused ? "resume" : "pause";
              handleSendGuildData(
                props.socket,
                props.guildID,
                false,
                action,
                false,
                props.userSnowflake
              );
            }}
          >
            {props.paused && "Resume"}
            {!props.paused && "Pause"}
          </Button>
          <Button
            className="skipBtn"
            onClick={() => {
              handleSendGuildData(
                props.socket,
                props.guildID,
                false,
                "skip",
                false,
                props.userSnowflake
              );
            }}
          >
            Skip
          </Button>
          <Button
            className="stopBtn"
            onClick={() => {
              handleSendGuildData(
                props.socket,
                props.guildID,
                false,
                "stop",
                false,
                props.userSnowflake
              );
            }}
          >
            Stop
          </Button>
          <div className="gridWMD gridHMD songTrackSource">
            From {trackSource}
          </div>
          <div className="gridWMD gridHMD songAddedBy">
            Added by: {props.vidInfo.added_by}
          </div>
        </div>
      )}
      {props.list && (
        <div className={"vidInfoContainer vidInfoContainerSM"}>
          <div className="songTitle gridWXL gridHMD" onClick={openVidLink}>
            {props.vidInfo.title} - [{dur}]
          </div>
          <div className="songTrackSource songTrackSourceSM gridHMD">
            From {trackSource}
          </div>
          <div className="songAddedBy songAddedBySM gridHMD">
            Added by: {props.vidInfo.added_by}
          </div>
          <Button
            className="rmSong gridHMD"
            onClick={() => {
              handleSendGuildData(
                props.socket,
                props.guildID,
                false,
                "wrongsong",
                { number: props.qi },
                props.userSnowflake
              );
            }}
          >
            Remove
          </Button>
        </div>
      )}
    </Fragment>
  );
}

function handleSendGuildData(
  socket,
  guildID,
  data,
  action,
  aData,
  snowflake,
  admin = false
) {
  socket.emit("g_data", {
    id: guildID,
    data: data,
    aData: aData,
    action: action,
    snowflake: snowflake,
    admin: admin,
  });
}

let inputTimeout;
function AudioTile(props) {
  const [songInput, setSongInput] = useState("");
  const [filteredAudioCache, setFilteredAudioCache] = useState([]);

  const handleSearchInput = (e) => {
    const value = e.target.value;
    setSongInput(value);
    clearTimeout(inputTimeout);
    inputTimeout = setTimeout(() => {
      if (value.length > 0)
        setFilteredAudioCache(props.getFilteredAudioCache(value));
      else setFilteredAudioCache([]);
    }, 500);
  };

  let vCList = props.g.voiceChannelArray.map((vc) => {
    return (
      <Dropdown.Item
        key={vc.id}
        onClick={() => {
          handleSendGuildData(
            props.socket,
            props.g.guildID,
            false,
            "join",
            { channel: vc.name },
            props.user.snowflake
          );
        }}
      >
        {vc.name}
      </Dropdown.Item>
    );
  });
  let queue = false;
  if (props.g.audioQueue) {
    queue = props.g.audioQueue.map((e, i) => {
      return (
        <SongItem
          key={i}
          vidInfo={{ ...e.info, added_by: e.added_by, details: e.details }}
          list={true}
          socket={props.socket}
          guildID={props.g.guildID}
          userSnowflake={props.user.snowflake}
          qi={i + 1}
        />
      );
    });
  }
  let nowPlaying = false;
  if (props.g.nowPlaying) {
    nowPlaying = (
      <SongItem
        vidInfo={props.g.nowPlaying}
        socket={props.socket}
        guildID={props.g.guildID}
        userSnowflake={props.user.snowflake}
        paused={props.g.paused}
      />
    );
  }

  return (
    <div className={"audioTile gridWXL gridHXL"}>
      <div className="dropdownContainer">
        <DropdownButton
          id={`voiceChannelDD_${props.g.guildID}`}
          className={(!props.g.voiceChannel && "dropdownFull") || ""}
          title={props.g.voiceChannel.name || "Voice Channel"}
        >
          {vCList}
        </DropdownButton>
        {props.g.voiceChannel && (
          <Button
            className="leaveVCButton"
            onClick={() => {
              handleSendGuildData(
                props.socket,
                props.g.guildID,
                false,
                "leave",
                false,
                props.user.snowflake
              );
            }}
          >
            Leave
          </Button>
        )}
      </div>
      <FormControl
        type="text"
        placeholder="Search terms or YT/SC link"
        value={songInput}
        className="songSearch"
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            if (songInput !== "") {
              handleSendGuildData(
                props.socket,
                props.g.guildID,
                false,
                "play",
                { search: songInput },
                props.user.snowflake
              );
              setSongInput("");
              setFilteredAudioCache([]);
            }
          }
        }}
        onChange={(e) => {
          handleSearchInput(e);
        }}
      />
      <Button
        className="songInputSubmit"
        type="submit"
        onClick={() => {
          if (songInput !== "") {
            handleSendGuildData(
              props.socket,
              props.g.guildID,
              false,
              "play",
              { search: songInput },
              props.user.snowflake
            );
            setSongInput("");
          }
        }}
      >
        +
      </Button>
      {filteredAudioCache.length > 0 && (
        <ul className="cacheSearchResults">
          {filteredAudioCache.map((i) => {
            return (
              <li
                className="cacheSearchItem"
                key={i._id}
                onClick={(e) => {
                  e.preventDefault();
                  handleSendGuildData(
                    props.socket,
                    props.g.guildID,
                    false,
                    "play",
                    { search: i.title },
                    props.user.snowflake
                  );
                  setSongInput("");
                  setFilteredAudioCache([]);
                }}
              >
                <div className="cacheSearchItemTitle">{i.title}</div>
                <div className="cacheSearchItemDuration">
                  {msToTime(i.duration * 1000)}
                </div>
                <div className="cacheSearchItemLP">
                  {() => {
                    const date = new Date(i.lastPlayed);
                    const hours = {
                      number:
                        date.getHours() > 12
                          ? date.getHours() - 12
                          : date.getHours(),
                      cycle: date.getHours() > 12 ? "PM" : "AM",
                    };
                    return `${
                      date.getMonth() + 1
                    }/${date.getDate()}/${date.getFullYear()} at ${
                      hours.number
                    }:${date.getMinutes()} ${hours.cycle}`;
                  }}
                </div>
                <img
                  src={!!i.imgURL ? i.imgURL : ""}
                  alt="Failed to load thumbnail."
                  className="cacheSearchItemThumbnail"
                />
              </li>
            );
          })}
        </ul>
      )}
      <div className="nowPlaying">
        {"Now Playing: "}
        {props.g.nowPlaying && nowPlaying}
        {!props.g.nowPlaying && "Nothing."}
      </div>
      <div className="audioQueueContainer">
        {"Queue: "}
        {!props.g.audioQueue.length && "Empty. Like my soul."}
        <div className="audioQueueList">{props.g.audioQueue && queue}</div>
      </div>
    </div>
  );
}

function GuildAdminControls(props) {
  let [defaultVC, setDefaultVC] = useState(props.g.defaultVoiceChannel);
  let [defaultTC, setDefaultTC] = useState(props.g.defaultTextChannel);
  let [welcome, setWelcome] = useState(props.g.welcomeMsg);
  let [welcomeTC, setWelcomeTC] = useState(props.g.welcomeTextChannel);
  let [ruleTC, setRuleTC] = useState(props.g.ruleTextChannel);
  let [anno, setAnno] = useState(props.g.announcements);
  let [annoRole, setAnnoRole] = useState(props.g.announcementsRole);
  let [newMem, setNewMem] = useState(props.g.newMember);
  let [newMemRole, setNewMemRole] = useState(props.g.newMemberRole);
  let g = props.g;

  useEffect(() => {
    setDefaultVC(props.g.defaultVoiceChannel);
    setDefaultTC(props.g.defaultTextChannel);
    setWelcome(props.g.welcomeMsg);
    setWelcomeTC(props.g.welcomeTextChannel);
    setRuleTC(props.g.ruleTextChannel);
    setAnno(props.g.announcements);
    setAnnoRole(props.g.announcementsRole);
    setNewMem(props.g.newMember);
    setNewMemRole(props.g.newMemberRole);
  }, [props]);

  let vCList = g.voiceChannelArray.map((vc) => {
    return (
      <Dropdown.Item
        key={vc.id}
        onClick={() => {
          setDefaultVC(vc);
          handleSendGuildData(
            props.socket,
            g.guildID,
            { defaultVoiceChannel: vc },
            false,
            false,
            props.userSnowflake,
            true
          );
        }}
      >
        {vc.name}
      </Dropdown.Item>
    );
  });
  let dTCList = g.textChannelArray.map((tc) => {
    return (
      <Dropdown.Item
        key={tc.id}
        onClick={() => {
          setDefaultTC(tc);
          handleSendGuildData(
            props.socket,
            g.guildID,
            { defaultTextChannel: tc },
            false,
            false,
            props.userSnowflake,
            true
          );
        }}
      >
        {tc.name}
      </Dropdown.Item>
    );
  });
  let wTCList = g.textChannelArray.map((tc) => {
    return (
      <Dropdown.Item
        key={tc.id}
        onClick={() => {
          setWelcomeTC(tc);
          handleSendGuildData(
            props.socket,
            g.guildID,
            { welcomeTextChannel: tc },
            false,
            false,
            props.userSnowflake,
            true
          );
        }}
      >
        {tc.name}
      </Dropdown.Item>
    );
  });
  let rTCList = g.textChannelArray.map((tc) => {
    return (
      <Dropdown.Item
        key={tc.id}
        onClick={() => {
          setRuleTC(tc);
          handleSendGuildData(
            props.socket,
            g.guildID,
            { ruleTextChannel: tc },
            false,
            false,
            props.userSnowflake,
            true
          );
        }}
      >
        {tc.name}
      </Dropdown.Item>
    );
  });
  let aRList = g.roleArray.map((r) => {
    return (
      <Dropdown.Item
        key={r.id}
        onClick={() => {
          setAnnoRole(r);
          handleSendGuildData(
            props.socket,
            g.guildID,
            { announcementsRole: r },
            false,
            false,
            props.userSnowflake,
            true
          );
        }}
      >
        {r.name}
      </Dropdown.Item>
    );
  });
  let nmRList = g.roleArray.map((r) => {
    return (
      <Dropdown.Item
        key={r.id}
        onClick={() => {
          setNewMemRole(r);
          handleSendGuildData(
            props.socket,
            g.guildID,
            { newMemberRole: r },
            false,
            false,
            props.userSnowflake,
            true
          );
        }}
      >
        {r.name}
      </Dropdown.Item>
    );
  });

  return (
    <Fragment>
      <DropdownButton
        id={`defaultVCDD_${g.guildID}`}
        className={"dropdownFull gridWXL"}
        title={
          (!!defaultVC && `Default Voice Channel: ${defaultVC.name}`) ||
          "Select Default Voice Channel"
        }
      >
        {vCList}
      </DropdownButton>
      <DropdownButton
        id={`defaultTCDD_${g.guildID}`}
        className={"dropdownFull gridWXL"}
        title={
          (!!defaultTC && `Default Text Channel: ${defaultTC.name}`) ||
          "Select Default Text Channel"
        }
      >
        {dTCList}
      </DropdownButton>
      <label
        className={
          (welcome && "gridWXL toggleSwitch") ||
          (!welcome && "gridWMD toggleSwitch")
        }
      >
        <div>{"Welcome Message:"}</div>
        <input
          key={Math.random()}
          type="checkbox"
          defaultChecked={welcome}
          onChange={(e) => {
            setWelcome(e.target.checked);
            handleSendGuildData(
              props.socket,
              g.guildID,
              { welcomeMsg: e.target.checked },
              false,
              false,
              props.userSnowflake,
              true
            );
          }}
        />
        <span className="toggleSlider" />
      </label>
      <DropdownButton
        id={`welcomeTCDD_${g.guildID}`}
        className={
          (!welcome && "hidden") || (welcome && "dropdownFull gridWXL")
        }
        title={
          (!!welcomeTC && `Welcome Text Channel: ${welcomeTC.name}`) ||
          "Select Welcome Text Channel"
        }
        disabled={!welcome}
      >
        {wTCList}
      </DropdownButton>
      <DropdownButton
        id={`ruleTCDD_${g.guildID}`}
        className={
          (!welcome && "hidden") || (welcome && "dropdownFull gridWXL")
        }
        title={
          (!!ruleTC && `Rules Text Channel: ${ruleTC.name}`) ||
          "Select Rule Text Channel"
        }
        disabled={!welcome}
      >
        {rTCList}
      </DropdownButton>
      <label
        className={
          (anno && "gridWXL toggleSwitch") || (!anno && "gridWMD toggleSwitch")
        }
      >
        <div>{"Announcements:"}</div>
        <input
          key={Math.random()}
          type="checkbox"
          defaultChecked={anno}
          onChange={(e) => {
            setAnno(e.target.checked);
            handleSendGuildData(
              props.socket,
              g.guildID,
              { announcements: e.target.checked },
              false,
              false,
              props.userSnowflake,
              true
            );
          }}
        />
        <span className="toggleSlider" />
      </label>
      <DropdownButton
        id={`annoRoleDD_${g.guildID}`}
        className={(!anno && "hidden") || (anno && "dropdownFull gridWXL")}
        title={
          (!!annoRole && `Announcements Role: ${annoRole.name}`) ||
          "Select Announcements Role"
        }
        disabled={!anno}
      >
        {aRList}
      </DropdownButton>
      <label
        className={
          (newMem && "gridWXL toggleSwitch") ||
          (!newMem && "gridWMD toggleSwitch")
        }
      >
        <div>{"New Member Role"}</div>
        <input
          key={Math.random()}
          type="checkbox"
          defaultChecked={newMem}
          onChange={(e) => {
            setNewMem(e.target.checked);
            handleSendGuildData(
              props.socket,
              g.guildID,
              { newMember: e.target.checked },
              false,
              false,
              props.userSnowflake,
              true
            );
          }}
        />
        <span className="toggleSlider" />
      </label>
      <DropdownButton
        id={`newMemRoleDD_${g.guildID}`}
        className={(!newMem && "hidden") || (newMem && "dropdownFull gridWXL")}
        title={
          (!!newMemRole && `New Member Role: ${newMemRole.name}`) ||
          "Select New Member Role"
        }
        disabled={!newMem}
      >
        {nmRList}
      </DropdownButton>
    </Fragment>
  );
}

class Guild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdmin: false,
      g: props.g,
    };
    this.toggleAdmin = this.toggleAdmin.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.g !== this.props.g) {
      this.setState({ g: this.props.g });
    }
  }
  toggleAdmin() {
    this.setState({ showAdmin: !this.state.showAdmin });
  }
  render() {
    return (
      <div className="gCTile" key={this.state.g.guildID}>
        {this.state.g.admin && <h4>{`${this.state.g.guildName} (admin)`}</h4>}
        {!this.state.g.admin && <h4>{this.state.g.guildName}</h4>}
        <div className="gControlsContainer">
          <AudioTile
            g={this.state.g}
            user={this.props.user}
            socket={this.props.socket}
            audioCache={this.props.audioCache}
            getFilteredAudioCache={this.props.getFilteredAudioCache}
          />
          {this.state.g.admin && (
            <div className="gAdminExpander gridWXL">
              <button onClick={this.toggleAdmin} className="gridWXL">
                Admin Controls
              </button>
              {this.state.showAdmin && (
                <div className="gAdminControlsContainer gridWXL">
                  <GuildAdminControls
                    g={this.state.g}
                    socket={this.props.socket}
                    userSnowflake={this.props.user.snowflake}
                  ></GuildAdminControls>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default function GuildControls(props) {
  const guilds = props.cData.guilds.map((g) => {
    return (
      <Guild
        key={g.guildID}
        g={g}
        user={props.user}
        socket={props.socket}
        audioCache={props.cData.audioCache}
        getFilteredAudioCache={props.getFilteredAudioCache}
      />
    );
  });
  return <div className="gContainer">{guilds}</div>;
}
