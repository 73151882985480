import { apiFetch } from "../util";
import queryString from "query-string";
import { Fragment, useState, useEffect } from "react";

export default function Verify() {
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    console.log(err, success);
    if (!err && !success) {
      const urlparams = queryString.parse(window.location.search);
      const tkn = urlparams.tkn ? urlparams.tkn : false;
      if (!!tkn) {
        const params = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            tkn: tkn,
          },
          method: "GET",
        };
        apiFetch("verify", params).then((result) => {
          window.history.pushState({}, document.title, "/verify");
          if (result.err) {
            console.log(result.err);
            return setErr(result.err);
          }
          return setSuccess(result.data);
        });
      } else {
        console.log("No Token");
        return setErr("No Token");
      }
    }
  }, [err, success]);
  return (
    <Fragment>
      {success && (
        <Fragment>
          <h2>
            Your email is verified! You can confirm in your profile page once
            you're logged in.
          </h2>
          <a href="/profile">Take me there!</a>
        </Fragment>
      )}
      {!success && <h2>Verifying...</h2>}
      {!!err && (
        <Fragment>
          <h2>
            There was a problem verifying your email. Please request another
            link from your profile page.
          </h2>
          <a href="/profile">Take me there!</a>
        </Fragment>
      )}
    </Fragment>
  );
}
