import AuthPage from "../components/voidbot/AuthPage";
import GuildControls from "../components/voidbot/GuildControls";
import AdminConsole from "../components/voidbot/AdminConsole";
import { useOutletContext } from "react-router-dom";
import io from "socket.io-client";
import { Component, Fragment, useEffect } from "react";
import "../components/voidbot/VoidBot.css";

export default function VoidBotWebUI() {
  const [user, setUser] = useOutletContext();
  useEffect(() => {
    document.getElementById("title").innerText = "VoidBot Web UI";
  }, []);
  return <WebUIContent user={user} setUser={setUser}></WebUIContent>;
}

class WebUIContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      setUser: props.setUser,
      cData: false,
      tempToken: "",
      backlog: [],
      cmdList: [],
      botAdmin: false,
    };
    this.initSocket = () => {
      const socket = io("https://speags.com", { path: "/apis/voidbot/" });
      socket.once("connect", () => {
        socket.once("handshake", () => {
          return socket.emit("handshake_res", this.state.user.snowflake);
        });
        socket.once("handshake_end", (botAdmin = false) => {
          this.setState({ botAdmin: botAdmin, socket: socket });
          socket.once("init_data_res", (payload) => {
            let stateOut = {
              cData: { audioCache: payload.audioCache, guilds: payload.guilds },
            };
            if (!!botAdmin) {
              stateOut.backlog = payload.console.backlog;
              stateOut.cmdList = payload.console.cmdToggles;
              socket.on("stdout", (lo) => {
                let logsOut = [...this.state.backlog];
                logsOut.push(lo);
                return this.setState({ backlog: logsOut });
              });
              socket.on("stdout_check", () => {
                socket.emit("stdout_auth", this.state.user.snowflake);
              });
            }
            this.setState(stateOut);
          });
          socket.emit(
            "init_data",
            this.state.user.snowflake,
            botAdmin ? ["guilds", "console"] : ["guilds"],
            this.state.user.guilds
          );
        });
        socket.on("guild_partial", (payload) => {
          let cData = this.state.cData;
          let target = cData.guilds.find(
            (guild) => guild.guildID === payload.guildID
          );
          let targetIndex = cData.guilds.findIndex(
            (guild) => guild.guildID === payload.guildID
          );
          for (let i of Object.keys(payload.data)) {
            target[i] = payload.data[i];
          }
          cData.guilds[targetIndex] = target;
          return this.setState({ cData: cData });
        });
        socket.on("base_data", (data) => {
          Object.keys(data).forEach((k) => {
            switch (k) {
              case "audioCache": {
                const { cData } = this.state;
                if (data[k].remove) {
                  const index = cData.audioCache.indexOf(
                    cData.audioCache.find(
                      (s) => s._id === data.audioCache.info._id
                    )
                  );
                  cData.audioCache.splice(index, 1);
                  return this.setState({ cData: cData });
                } else {
                  cData.audioCache.push(data[k].info);
                  return this.setState({ cData: cData });
                }
              }
              default: {
                break;
              }
            }
          });
        });
        socket.emit("ready");
        return socket;
      });
    };
    this.handleSendCMD = this.handleSendCMD.bind(this);
    this.getFilteredAudioCache = this.getFilteredAudioCache.bind(this);
  }

  componentDidUpdate(oldProps, oldState) {
    if (
      oldProps.user !== this.props.user ||
      oldState.user !== this.state.user
    ) {
      let { user } = this.state;
      if (oldProps.user !== this.props.user && !!this.props.user)
        user = this.props.user;
      if (oldProps.user !== this.props.user && !this.props.user) user = false;
      if (
        !this.state.socket &&
        !!this.state.user &&
        !!this.state.user.snowflake
      )
        return this.setState({
          socket: this.initSocket(),
        });
      if (!!this.props.user && !this.state.user)
        return this.setState({ user: user });
    }
  }

  componentDidMount() {
    if (!!this.state.user && !!this.state.user.snowflake)
      return this.setState({ socket: this.initSocket() });
  }
  componentWillUnmount() {
    if (!!this.state.socket) {
      this.state.socket.close();
      return this.setState({ socket: false });
    }
  }
  handleSendCMD(input, data = false) {
    return this.state.socket.emit("sysCMD", {
      snowflake: this.state.user.snowflake,
      cmd: input,
      data: data,
    });
  }

  getFilteredAudioCache(filter) {
    const levenshteinDistance = (a, b) => {
      if (a.length === 0) return b.length;
      if (b.length === 0) return a.length;

      const matrix = [];

      for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
      }

      for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
      }

      for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
          const cost = a[j - 1] === b[i - 1] ? 0 : 1;
          matrix[i][j] = Math.min(
            matrix[i - 1][j] + 1,
            matrix[i][j - 1] + 1,
            matrix[i - 1][j - 1] + cost
          );
        }
      }

      return matrix[b.length][a.length];
    };

    const closestMatches = this.state.cData.audioCache
      .map((item) => {
        const distances = Array.from(
          { length: item.title.length - filter.length + 1 },
          (_, index) =>
            levenshteinDistance(
              filter.toLowerCase(),
              item.title.toLowerCase().substring(index, index + filter.length)
            )
        );
        return {
          item,
          distance: Math.min(...distances),
        };
      })
      .sort((a, b) => a.distance - b.distance)
      .slice(0, 10)
      .map((m) => m.item);

    return closestMatches;
  }

  render() {
    const { user, cData, backlog, cmdList, socket, botAdmin } = this.state;
    return (
      <Fragment>
        {!user && <AuthPage user={user} />}
        {!!user && !user.snowflake && <AuthPage user={user} />}
        {!!user && !!user.snowflake && !cData && "Loading..."}
        {!!user && !!user.snowflake && !!cData && (
          <Fragment>
            {botAdmin && (
              <AdminConsole
                backlog={backlog}
                cmdList={cmdList}
                doSendCMD={this.handleSendCMD}
              />
            )}
            <GuildControls
              cData={cData}
              user={user}
              socket={socket}
              getFilteredAudioCache={this.getFilteredAudioCache}
            />
          </Fragment>
        )}
      </Fragment>
    );
  }
}
